import { notNullish } from "@farmact/model/src/utils/array";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx";
import { CSSProperties, forwardRef } from "react";
import "./faIcon.scss";

type FaIconProps = FontAwesomeIconProps & {
    color?: FaIconColor;
    spacing?: "right" | "left";
    /**
     * @default "0.5rem"
     */
    spacingSize?: `${number}rem`;
};

// needs forward ref else hover effects will not work
export const FaIcon = forwardRef(function FaIcon(props: FaIconProps, ref: React.Ref<SVGSVGElement>) {
    const { color, spacing, spacingSize = "0.5rem", className, ...restProps } = props;

    return (
        <FontAwesomeIcon
            ref={ref}
            {...restProps}
            className={clsx(
                "fa-icon",
                {
                    "fa-icon--color-primary": color === "primary",
                    "fa-icon--color-secondary": color === "secondary",
                    "fa-icon--color-warning": color === "warning",
                    "fa-icon--color-danger": color === "danger",
                    "fa-icon--color-medium": color === "medium",
                    "fa-icon--color-dark": color === "dark",
                },
                className
            )}
            style={mergeIconStyles(getIconStyle(spacing, spacingSize), restProps.style)}
        />
    );
});

export type FaIconColor = "primary" | "secondary" | "warning" | "danger" | "medium" | "dark";

type SpacingSize = `${number}rem`;
type SpacingSide = "left" | "right";

function getIconStyle(
    spacingSide: SpacingSide | undefined,
    spacingSize: SpacingSize | undefined
): CSSProperties | undefined {
    if (spacingSide === undefined || spacingSize === undefined) {
        return;
    }

    switch (spacingSide) {
        case "left":
            return { marginLeft: spacingSize };
        case "right":
            return { marginRight: spacingSize };
    }
}

function mergeIconStyles(...styles: Array<CSSProperties | undefined>): CSSProperties | undefined {
    const definedStyles = styles.filter(notNullish);
    if (definedStyles.length === 0) {
        return undefined;
    }

    return definedStyles.reduce(
        (acc, cur) => ({
            ...acc,
            ...cur,
        }),
        {}
    );
}
