import * as Collapsible from "@/components/FaCollapsible";
import { useScreenIsAtLeast } from "@/util/customHooks/useScreenIsAtLeast";
import "./receiptsTableCardRoot.scss";

type ReceiptsTableCardRootProps = {
    title: React.ReactNode;
    /**
     * disable collapsible behaviour.
     * this should be set to `false` if only one receipts card is used on the page
     * @default true
     */
    collapsible?: boolean;

    children: React.ReactNode;
};

export function ReceiptsTableCardRoot(props: ReceiptsTableCardRootProps) {
    const { collapsible = true } = props;

    const isLgScreeSizeOrUp = useScreenIsAtLeast("lg");

    return (
        <div className="receipts-table-card-root">
            <Collapsible.Root defaultOpen={!collapsible || isLgScreeSizeOrUp} disabled={!collapsible}>
                <Collapsible.Trigger>
                    <div className="receipts-table-card-root__header">
                        <h4 className="receipts-table-card-root__title">{props.title}</h4>
                        <Collapsible.Icon />
                    </div>
                </Collapsible.Trigger>

                <Collapsible.Body className="receipts-table-card-root__body">{props.children}</Collapsible.Body>
            </Collapsible.Root>
        </div>
    );
}
