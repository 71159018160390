/**
 * Use this to filter an array and only keep the existing values (all except `null` and `undefined`)
 *
 * Example:
 * ```ts
 * const a = ["hello", undefined, "world", null, "!"];
 * const b = a.filter(notNullish); // gives ["hello", "world", "!"]
 * ```
 */
export function notNullish<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export function isNullish(value: any): value is null | undefined {
    return value === null || value === undefined;
}
