import { useEffect, useState } from "react";

export function useMediaQuery(query: string) {
    const [match, setMatch] = useState(window.matchMedia(query).matches);

    useEffect(() => {
        const mediaQuery = window.matchMedia(query);

        const onChange = () => {
            setMatch(mediaQuery.matches);
        };

        /**
         * Fallback for old Browsers (Safari < 14)
         * @see https://caniuse.com/mdn-api_mediaquerylist_addlistener
         */
        if (typeof mediaQuery.addEventListener === "function") {
            mediaQuery.addEventListener("change", onChange);
        } else {
            mediaQuery.addListener(onChange);
        }
        setMatch(mediaQuery.matches);

        return () => {
            /**
             * Fallback for old Browsers (Safari < 14)
             * @see https://caniuse.com/mdn-api_mediaquerylist_addlistener
             */
            if (typeof mediaQuery.removeEventListener === "function") {
                mediaQuery.removeEventListener("change", onChange);
            } else {
                mediaQuery.removeListener(onChange);
            }
        };
    }, [query]);

    return match;
}
